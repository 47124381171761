import React, { useEffect, useState } from 'react';
import { InstantSearch, connectHits, connectSearchBox, Configure } from 'react-instantsearch-dom';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { Card, Row, Col, Form, InputGroup, Button, Modal } from 'react-bootstrap';
import { FaTrash, FaUsers, FaTshirt, FaTasks, FaSmoking, FaMapMarkerAlt, FaMountain, FaLightbulb, FaTools, FaCalendarAlt, FaFireAlt, FaSearch, FaExclamationTriangle } from 'react-icons/fa';
import moment from 'moment';
import 'moment/locale/zh-tw';
import '../App.css'; // or './index.css'

moment.locale('zh-tw');

// Configure the Typesense InstantSearch adapter
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
        apiKey: 'xyz',
        nodes: [
            {
                host: 'typesense.dtix.tw',
                port: '443',
                protocol: 'https',
            },
        ],
    },
    additionalSearchParameters: {
        query_by: 'name,description,activity,dangerous_environment,clothing,position,location,ground,lighting,equipment_type',
        hitsPerPage: 100,
    },
});

const searchClient = typesenseInstantsearchAdapter.searchClient;

// Custom Hit component to display search results with highlights and image
const Hit = ({ hit, onImageClick }) => {
    const handleDelete = () => {
        fetch(`https://typesense.dtix.tw/collections/hotel/documents/${hit.id}`, {
            method: 'DELETE',
            headers: {
                'X-TYPESENSE-API-KEY': 'xyz',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Document deleted:', data);
                window.location.reload();
            })
            .catch(error => {
                console.error('Error deleting document:', error);
            });
    };

    const isDangerous = hit.warning === true;

    return (
        <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card className="h-100 position-relative" style={{ border: '1px solid #ddd', borderRadius: '5px', backgroundColor: isDangerous ? '#f8d7da' : '#fff' }}>
                {hit.image_url && (
                    <Card.Img
                        variant="top"
                        src={hit.thumbnail_url}
                        alt={hit.description}
                        style={{ maxHeight: '200px', objectFit: 'cover', cursor: 'pointer' }}
                        onClick={() => onImageClick(hit.image_url)}
                    />
                )}
                <Card.Body>
                    <Card.Title className="text-primary">{hit.name}</Card.Title>
                    <Card.Text style={{ fontSize: '0.75rem' }}>{hit.description}</Card.Text>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        <span className="d-flex align-items-center gap-2">
                            <FaUsers style={{ color: '#007bff', fontSize: '1rem' }} /> {hit.number_of_people}
                        </span>
                        <span className="d-flex align-items-start gap-2">
                            <FaTshirt style={{ color: '#007bff', fontSize: '1rem', flexShrink: 0 }} />
                            <span style={{ fontSize: '1rem', lineHeight: '1.2' }}>{hit.clothing}</span>
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <FaTasks style={{ color: '#007bff', fontSize: '1rem' }} /> {hit.activity}
                        </span>
                        {hit.people_smoking && (
                            <span className="d-flex align-items-center gap-2">
                                <FaSmoking style={{ color: 'red', fontSize: '1rem' }} /> 有人抽煙
                            </span>
                        )}
                        <span className="d-flex align-items-center gap-2">
                            <FaMapMarkerAlt style={{ color: '#007bff', fontSize: '1rem' }} /> {hit.position}
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <FaMountain style={{ color: '#007bff', fontSize: '1rem' }} /> {hit.location}
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <FaLightbulb style={{ color: '#007bff', fontSize: '1rem' }} /> {hit.lighting}
                        </span>
                        {hit.equipment_quantity !== 0 && (
                            <>
                                <span className="d-flex align-items-center gap-2">
                                    <FaTools style={{ color: '#007bff', fontSize: '1rem' }} /> {hit.equipment_quantity}
                                </span>
                                <span className="d-flex align-items-center gap-2">
                                    <FaTools style={{ color: '#007bff', fontSize: '1rem' }} /> {hit.equipment_type}
                                </span>
                            </>
                        )}
                        {hit.dangerous_environment !== "無" && hit.dangerous_environment && (
                            <span className="d-flex align-items-center gap-2">
                                <FaFireAlt style={{ color: 'red', fontSize: '1rem' }} /> {hit.dangerous_environment}
                            </span>
                        )}
                        {hit.crowded && (
                            <span className="d-flex align-items-center gap-2">
                                <FaUsers style={{ color: 'red', fontSize: '1rem' }} /> 擁擠
                            </span>
                        )}
                        {hit.hallway_clutter && (
                            <span className="d-flex align-items-center gap-2">
                                <FaTools style={{ color: 'red', fontSize: '1rem' }} /> 走廊雜亂
                            </span>
                        )}
                        {hit.conflict_present && (
                            <span className="d-flex align-items-center gap-2">
                                <FaExclamationTriangle style={{ color: 'red', fontSize: '1rem' }} /> 口角/打鬥
                            </span>
                        )}
                        <span className="d-flex align-items-center gap-2">
                            <FaCalendarAlt style={{ color: '#007bff', fontSize: '1rem' }} /> {moment(hit.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                    </div>
                </Card.Body>
                <button
                    onClick={handleDelete}
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    <FaTrash style={{ color: 'grey' }} />
                </button>
            </Card>
        </Col>
    );
};

const CustomHits = ({ hits, onImageClick }) => (
    <Row className="mt-4 hits-container">
        {hits.map(hit => (
            <Hit key={hit.objectID} hit={hit} onImageClick={onImageClick} />
        ))}
    </Row>
);

const ConnectedHits = connectHits(CustomHits);

const CustomSearchBox = ({ currentRefinement, refine }) => (
    <Form onSubmit={e => e.preventDefault()}>
        <InputGroup className="mb-3">
            <Form.Control
                type="text"
                placeholder="Search..."
                aria-label="Search"
                value={currentRefinement}
                onChange={e => refine(e.currentTarget.value)}
            />
            <Button variant="outline-secondary" onClick={() => refine(currentRefinement)}>
                <FaSearch />
            </Button>
        </InputGroup>
    </Form>
);

const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

function HotelSearch() {
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [filters, setFilters] = useState({
        warning: false,
        people_smoking: false,
        crowded: false,
        hallway_clutter: false,
        conflict_present: false // Added conflict_present filter
    });



    const handleImageClick = (imageUrl) => {
        setModalImage(imageUrl);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    // const handleFilterChange = (e) => {
    //     const { name, checked } = e.target;
    //     setFilters(prevFilters => ({
    //         ...prevFilters,
    //         [name]: checked ? true : false  // Explicitly set filters to true or false
    //     }));
    // };

    const handleFilterChange = (e) => {
        const { name, checked } = e.target;

        if (checked) {
            // If checked, set only the selected filter to true, others to false
            setFilters({
                warning: false,
                people_smoking: false,
                crowded: false,
                hallway_clutter: false,
                conflict_present: false,
                [name]: true, // Set the clicked filter to true
            });
        } else {
            // If unchecked, reset all filters to false
            setFilters({
                warning: false,
                people_smoking: false,
                crowded: false,
                hallway_clutter: false,
                conflict_present: false,
            });
        }
    };



    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                window.location.reload();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const buildFilterString = () => {
        const activeFilters = Object.keys(filters).filter(filter => filters[filter] === true);
        if (activeFilters.length === 0) return ''; // If no filters are selected, return an empty string

        return activeFilters
            .map(filter => `${filter}:true`)  // Explicitly set only true filters
            .join(' AND ');
    };





    return (
        <div className="container mt-4 text-center">
            <h1>飯店多相機智慧即時監看</h1>
            <p className="text-center text-danger small">請按Enter更新資料</p>

            <Form.Group className="custom-checkbox mb-3 d-flex justify-content-center gap-3">
                <Form.Check
                    type="checkbox"
                    label="只顯示警告"
                    name="warning"
                    checked={filters.warning}
                    onChange={handleFilterChange}
                    className="custom-checkbox-item"
                />
                <Form.Check
                    type="checkbox"
                    label="有人抽煙"
                    name="people_smoking"
                    checked={filters.people_smoking}
                    onChange={handleFilterChange}
                    className="custom-checkbox-item"
                />
                <Form.Check
                    type="checkbox"
                    label="擁擠"
                    name="crowded"
                    checked={filters.crowded}
                    onChange={handleFilterChange}
                    className="custom-checkbox-item"
                />
                <Form.Check
                    type="checkbox"
                    label="走廊雜亂"
                    name="hallway_clutter"
                    checked={filters.hallway_clutter}
                    onChange={handleFilterChange}
                    className="custom-checkbox-item"
                />
                <Form.Check
                    type="checkbox"
                    label="口角/打鬥"
                    name="conflict_present"
                    checked={filters.conflict_present}
                    onChange={handleFilterChange}
                    className="custom-filter"
                />
            </Form.Group>



            <InstantSearch searchClient={searchClient} indexName="hotel">
                <Configure
                    hitsPerPage={100}
                    filters={buildFilterString()}  // Dynamically apply filters
                />
                <ConnectedSearchBox />
                <ConnectedHits onImageClick={handleImageClick} />
            </InstantSearch>

            <Modal show={showModal} onHide={handleCloseModal} centered dialogClassName="custom-modal-dialog">
                <Modal.Body className="custom-modal-body d-flex justify-content-center align-items-center p-0 position-relative">
                    <button onClick={handleCloseModal} className="close-button">&times;</button>
                    <img
                        src={modalImage}
                        alt="Enlarged view"
                        style={{
                            width: '100%',
                            maxWidth: '500px',
                            objectFit: 'contain',
                            display: 'block',
                            margin: '0px'
                        }}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default HotelSearch;
