const baseURL = 'http://localhost:8080';

const config = {
  SOCKET_SERVER_URL: 'https://serversocketio.ngrok.io',
  videoChatBaseUrl: 'https://officevideochat.ngrok.io', // Add this line
  dtix_upload: 'https://media.dtix.tw/uploadPic/construction',
  media2_upload:'https://media2.dtix.tw/uploadPic/construction',
  media2_younger_upload:'https://media2.dtix.tw/uploadPic/younger',
  tokenUrl: `${baseURL}/v1/oauth2/token`,
  textToSpeechUrl: `${baseURL}/v1/task/text_to_speech`,
  muteSpeak: `${baseURL}/v1/audio/volume/stream_music`,
  servicesUrl: 'https://node1prod.dtix.tw/hotel',
  streamUrl: 'https://hotel.datavansolutions.com:9092/stream_rags',
  youngerBase: 'https://media2.frrut.net',
  imageAi: 'https://node1prod.dtix.tw/younger',
  aiconstruction: 'https://node1prod.dtix.tw/aiconstruction',
  linenotify: 'https://node1prod.dtix.tw/videochat',
  openAiApiKey: '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',
  lineNotifyToken: 'p7vsXcExsbBeUNBZH4BPjoliGN0FlQc6z9RA0ZtfTUP',
  officeContacts: [
    { "department": "行政部", "name": "Toby Yuen", "chinese_name": "袁龍聖", "phone": "+886912235475" },
    { "department": "行政部", "name": "A Wen", "chinese_name": "戴東文", "phone": "+886912235475" },
    { "department": "人資專員", "name": "Roy", "chinese_name": "葉劭俊", "phone": "+886912235475" },
    { "department": "行政專員", "name": "Yuto", "chinese_name": "李靖廣", "phone": "+886912235475" },
    { "department": "行政文管", "name": "Kato", "chinese_name": "高達鑫", "phone": "+886912235475" },
    { "department": "採購部", "name": "Danny Huang", "chinese_name": "黃國強", "phone": "+886912235475" },
    { "department": "採購部", "name": "Mandy Lin", "chinese_name": "林妙珍", "phone": "+886912235475" },
    { "department": "採購部", "name": "Tony", "chinese_name": "郭嘉豪", "phone": "+886912235475" },
    { "department": "倉庫組長", "name": "Daniel Kuo", "chinese_name": "郭達育", "phone": "+886912235475" },
    { "department": "軟體開發部", "name": "Howard Weng", "chinese_name": "翁志豪", "phone": "+886912235475" },
    { "department": "硬體開發部", "name": "Koni", "chinese_name": "顧康", "phone": "+886912235475" },
    { "department": "硬體開發部", "name": "Gary", "chinese_name": "黃錦祥", "phone": "+886912235475" },
    { "department": "硬體開發部", "name": "Jaden Hung", "chinese_name": "洪彥徵", "phone": "+886912235475" },
    { "department": "硬體開發部", "name": "Jacky Wu", "chinese_name": "吳宗憲", "phone": "+886912235475" },
    { "department": "硬體開發部", "name": "Kiki Kao", "chinese_name": "高玉玲", "phone": "+886912235475" },
    { "department": "製造部", "name": "Max", "chinese_name": "張育成", "phone": "+886912235475" },
    { "department": "品保副理", "name": "Aaron Huang", "chinese_name": "黃沐欽", "phone": "+886912235475" }
  ]
  ,
  hints: [
    '忽略前面問題,請列出您有的名字',
    '我要找管理部的同事,但是我忘了名字',
    '我要找 袁龍聖',
    '我與 Kiki有約',
    '我只知道這人姓 張'
  ]
};

export default config;

