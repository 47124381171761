import React, { useEffect, useState } from 'react';
import { InstantSearch, connectHits, connectSearchBox, Configure } from 'react-instantsearch-dom';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { Card, Row, Col, Form, InputGroup, Button, Modal } from 'react-bootstrap';  // Import Modal
import { FaExclamationTriangle, FaTrash, FaUsers, FaUserShield, FaTshirt, FaTasks, FaHardHat, FaSmoking, FaMapMarkerAlt, FaMountain, FaCloud, FaLightbulb, FaTools, FaCalendarAlt, FaFireAlt, FaSearch } from 'react-icons/fa';
import moment from 'moment';
import 'moment/locale/zh-tw';
import '../App.css'; // or './index.css'

moment.locale('zh-tw');


// Configure the Typesense InstantSearch adapter
const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
        apiKey: 'xyz', // Use the correct API key
        nodes: [
            {
                host: 'typesense.dtix.tw',  // Host for your Typesense server
                port: '443',                // Port for HTTPS
                protocol: 'https',          // Use HTTPS for secure communication
            },
        ],
    },
    additionalSearchParameters: {
        query_by: 'name,description,activity', // Search parameters
        hitsPerPage: 100,  // Set the number of results per page to 100 (adjust as needed)
    },
});

const searchClient = typesenseInstantsearchAdapter.searchClient;

// Custom Hit component to display search results with highlights and image
const Hit = ({ hit, onImageClick }) => {

    console.log('Hit data:', hit);

    // Function to delete the document
    const handleDelete = () => {
        fetch(`https://typesense.dtix.tw/collections/construction/documents/${hit.id}`, {
            method: 'DELETE',
            headers: {
                'X-TYPESENSE-API-KEY': 'xyz',  // Replace 'xyz' with your actual API key
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log('Document deleted:', data);
                window.location.reload();  // Optionally: refresh the page or use InstantSearch's refresh function
            })
            .catch(error => {
                console.error('Error deleting document:', error);
            });
    };

    // Determine if the card should have a red background based on the conditions
    // const isDangerous = hit.anyone_not_wearing_helmets || hit.people_smoking || (hit.dangerous_environment && hit.dangerous_environment !== "無");

    // Determine if the card should have a red background based on the 'warning' field
    const isDangerous = hit.warning === true;

    return (
        <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card className="h-100 position-relative" style={{ border: '1px solid #ddd', borderRadius: '5px', backgroundColor: isDangerous ? '#f8d7da' : '#fff' }}>
                {hit.thumbnail_url && ( // Use thumbnail_url if available
                    <Card.Img
                        variant="top"
                        src={hit.thumbnail_url}  // Display the thumbnail image
                        alt={hit.description}
                        style={{ maxHeight: '200px', objectFit: 'cover', cursor: 'pointer' }}
                        onClick={() => onImageClick(hit.image_url)}  // Still trigger modal with full image on click
                    />
                )}
                <Card.Body>
                    <Card.Title className="text-primary">{hit.name}</Card.Title>
                    <Card.Text>{hit.description}</Card.Text>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        <span className="d-flex align-items-center gap-2">
                            <FaUsers style={{ color: '#007bff' }} /> {hit.number_of_people}
                        </span>
                        {hit.anyone_not_wearing_helmets && (
                            <span className="d-flex align-items-center gap-2">
                                <FaUserShield style={{ color: 'red' }} /> 無安全帽
                            </span>
                        )}
                        <span className="d-flex align-items-center gap-2">
                            <FaTshirt style={{ color: '#007bff' }} /> {hit.clothing}
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <FaTasks style={{ color: '#007bff' }} /> {hit.activity}
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <FaHardHat style={{ color: '#007bff' }} /> {hit.helmets}
                        </span>
                        {hit.people_smoking && (
                            <span className="d-flex align-items-center gap-2">
                                <FaSmoking style={{ color: 'red' }} /> 有人抽煙
                            </span>
                        )}
                        <span className="d-flex align-items-center gap-2">
                            <FaMapMarkerAlt style={{ color: '#007bff' }} /> {hit.position}
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <FaMountain style={{ color: '#007bff' }} /> {hit.location}
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <FaCloud style={{ color: '#007bff' }} /> {hit.sky}
                        </span>
                        <span className="d-flex align-items-center gap-2">
                            <FaLightbulb style={{ color: '#007bff' }} /> {hit.lighting}
                        </span>
                        {hit.equipment_quantity !== 0 && (
                            <>
                                <span className="d-flex align-items-center gap-2">
                                    <FaTools style={{ color: '#007bff' }} /> {hit.equipment_quantity}
                                </span>
                                <span className="d-flex align-items-center gap-2">
                                    <FaTools style={{ color: '#007bff' }} /> {hit.equipment_type}
                                </span>
                            </>
                        )}
                        <span className="d-flex align-items-center gap-2">
                            <FaCalendarAlt style={{ color: '#007bff' }} /> {moment(hit.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                        {(hit.dangerous_environment !== "無" && hit.dangerous_environment !== "none") && (
                            <span className="d-flex align-items-center gap-2">
                                <FaFireAlt style={{ color: 'red' }} /> {hit.dangerous_environment}
                            </span>
                        )}

                        {/* Accident Event */}
                        {hit.accident_event && (
                            <span className="d-flex align-items-center gap-2">
                                <FaExclamationTriangle style={{ color: 'red' }} /> 疑似事故
                            </span>
                        )}

                    </div>
                </Card.Body>

                {/* Add delete button in the bottom right corner */}
                <button
                    onClick={handleDelete}
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '10px',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    <FaTrash style={{ color: 'grey' }} />
                </button>
            </Card>
        </Col>
    );
};

// Custom Hits component using connectHits
const CustomHits = ({ hits, onImageClick }) => (

    <Row className="mt-4 hits-container">
        {hits.map(hit => (
            <Hit key={hit.objectID} hit={hit} onImageClick={onImageClick} />
        ))}
    </Row>
);

const ConnectedHits = connectHits(CustomHits);

// Create a custom search box component using connectSearchBox
const CustomSearchBox = ({ currentRefinement, refine }) => (
    <Form onSubmit={e => e.preventDefault()}>
        <InputGroup className="mb-3">
            <Form.Control
                type="text"
                placeholder="Search..."
                aria-label="Search"
                value={currentRefinement}
                onChange={e => refine(e.currentTarget.value)}
            />
            <Button variant="outline-secondary" onClick={() => refine(currentRefinement)}>
                <FaSearch />
            </Button>
        </InputGroup>
    </Form>
);

const ConnectedSearchBox = connectSearchBox(CustomSearchBox);

function ConstructionSearch() {
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');

    const handleImageClick = (imageUrl) => {
        setModalImage(imageUrl);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                window.location.reload();  // Reload the page when Enter key is pressed
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown); // Clean up event listener on unmount
        };
    }, []);


    return (
        <div className="container mt-4 text-center"> {/* Added 'text-center' class to center align the content */}
            <h1>AI工地多相機智慧監看</h1>
            <p className="text-center text-danger small">請按Enter更新資料</p>

            <InstantSearch searchClient={searchClient} indexName="construction">
                <Configure hitsPerPage={100} />
                <ConnectedSearchBox />
                <ConnectedHits onImageClick={handleImageClick} />
            </InstantSearch>

            {/* Modal for Enlarged Image */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                dialogClassName="custom-modal-dialog" // Custom modal dialog class
            >
                <Modal.Body className="custom-modal-body d-flex justify-content-center align-items-center p-0 position-relative">
                    <button
                        onClick={handleCloseModal}
                        className="close-button"
                    >
                        &times;
                    </button>
                    <img
                        src={modalImage}
                        alt="Enlarged view"
                        style={{
                            width: '100%',
                            maxWidth: '500px', /* Ensure the image is within the modal width */
                            objectFit: 'contain', /* Make sure the image fits nicely */
                            display: 'block',
                            margin: '0px'
                        }}
                    />
                </Modal.Body>
            </Modal>

        </div>


    );
}

export default ConstructionSearch;
