import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { FaRedo, FaUpload } from 'react-icons/fa'; // Import the FontAwesome icons
import config from './config'; // Import your config
import moment from 'moment';  // Import moment
import 'moment/locale/zh-tw';  // Import Traditional Chinese locale
import { FaCircle } from 'react-icons/fa';  // Import the FaCircle icon
moment.locale('zh-tw');  // Set the locale to Traditional Chinese (Taiwan)

function Construction() {
    const [isCameraOpen, setIsCameraOpen] = useState(true); // Camera is open by default
    const [uploadedImagePath, setUploadedImagePath] = useState(''); // State to store the image URL
    const [responseData, setResponseData] = useState(null); // State to store the AI response data
    const [cameraError, setCameraError] = useState(false); // State to track camera errors
    const [loading, setLoading] = useState(false); // State to track loading status
    const [noClothingDetected, setNoClothingDetected] = useState(false); // State to track if no clothing was detected
    const [videoConstraints, setVideoConstraints] = useState({ facingMode: 'user' }); // Default to front camera
    const [pastedImage, setPastedImage] = useState(null); // State to store pasted image
    const [modalVisible, setModalVisible] = useState(false); // State to control modal visibility
    const [deletedOrderId, setDeletedOrderId] = useState(null); // State to store the deleted order ID
    const webcamRef = useRef(null);
    const fileInputRef = useRef(null); // Ref for file input

    const keyTranslations = {
        number_of_people: '人數',
        clothing: '衣著',
        activity: '活動',
        position: '位置',
        location: '位置描述',
        ground: '地面',
        lighting: '光線',
        equipment_quantity: '設備數量',
        equipment_type: '設備類型',
        dangerous_environment: '危險環境',
        people_smoking: '有人吸煙',
        description: '說明',
        name: "酒店名稱",  // Updated to reflect hotel context
        timestamp: "時間",
        image_url: '相片',
        crowded: '擁擠',  // New translation for 'crowded'
        hallway_clutter: '走廊雜亂', // New translation for 'hallway_clutter'
        conflict_present: '衝突發生',  // New translation for 'conflict_present'
        warning: '警告', // New translation for 'warning'
        thumbnail_url: '縮圖',
    };



    useEffect(() => {
        setUploadedImagePath('');
        setNoClothingDetected(false);

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            setVideoConstraints({ facingMode: { exact: 'environment' } });
        } else {
            setVideoConstraints({ facingMode: 'user' });
        }
    }, []);

    const clearAllDataExceptImage = () => {
        setResponseData(null);
        setNoClothingDetected(false);
        setIsCameraOpen(false); // Keep the camera off if an image is uploaded
        setModalVisible(false);
        setDeletedOrderId(null);
        setCameraError(false);
    };

    const handleTakeSnapshot = async () => {
        clearAllDataExceptImage(); // Clear all data except the image

        if (webcamRef.current) {
            const image = webcamRef.current.getScreenshot();
            if (image) {
                setPastedImage(image); // Store the snapshot as the pasted image
                setIsCameraOpen(false); // Hide the camera preview
                await uploadFile(image); // Upload the snapshot directly after capture
            } else {
                console.error('Failed to capture the screenshot from the webcam.');
            }
        }
    };

    const handleUploadClick = async () => {
        clearAllDataExceptImage(); // Clear all data except the image

        if (pastedImage) {
            await uploadFile(pastedImage);
        } else if (isCameraOpen && webcamRef.current) {
            const image = webcamRef.current.getScreenshot();
            if (image) {
                setPastedImage(image); // Store the snapshot as the pasted image
                setIsCameraOpen(false); // Hide the camera preview
                await uploadFile(image); // Upload the snapshot
            }
        }
    };

    const handleStartOver = () => {
        // Stop all video tracks to release the camera
        if (webcamRef.current && webcamRef.current.stream) {
            webcamRef.current.stream.getTracks().forEach(track => track.stop());
        }

        // Reload the page to start fresh
        window.location.reload();
    };

    const handleFileChange = async (event) => {
        clearAllDataExceptImage(); // Clear all data except the image

        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPastedImage(reader.result);
                setIsCameraOpen(false); // Hide the camera preview
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePaste = async (event) => {
        clearAllDataExceptImage(); // Clear all data except the image

        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPastedImage(reader.result);
                    setIsCameraOpen(false); // Hide the camera preview
                };
                reader.readAsDataURL(blob);
            }
        }
    };

    const handleKeyPress = (event) => {
        event.preventDefault(); // Prevent any keypresses (text input) in the input field
    };

    const uploadFile = async (image) => {
        const filename = new Date().toISOString() + '.jpg';
        const blob = await fetch(image).then(res => res.blob());

        let formData = new FormData();
        formData.append('file', blob, filename); // Use the form-data key 'file'

        setLoading(true);

        try {
            const response = await fetch(config.media2_upload, { // Use the URL from config
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('檔案上傳失敗');
            }

            const data = await response.json();
            console.log('檔案上傳成功:', data);
            setUploadedImagePath(data.imgUrl); // Use 'imgUrl' from the response

            // Check if thumbnailUrl is returned by the server
            const thumbnailUrl = data.thumbnailUrl || '';  // Fallback to empty string if not present

            // Pass both imageUrl and thumbnailUrl to AI processing
            const aiResponse = await postImageAI(data.imgUrl, thumbnailUrl);
            setResponseData(aiResponse);
        } catch (error) {
            console.error('檔案上傳失敗:', error);
            alert('檔案上傳失敗: ' + error.message);
        } finally {
            setLoading(false);
        }
    };


    const postImageAI = async (imageUrl, thumbnailUrl) => {
        try {
            const response = await axios.post(config.aiconstruction, {
                command: "hotel_image",
                image_url: imageUrl,    // Full image URL
                thumbnail_url: thumbnailUrl // Thumbnail image URL
            });

            console.log('AI Processing Success:', JSON.stringify(response.data, null, 2));

            if (response.data === null) {
                console.log('無衣物被偵測');
                setNoClothingDetected(true);
            }

            return response.data;
        } catch (error) {
            console.error('AI Processing Failed:', error);
            return null;
        }
    };

    const closeModal = () => {
        setModalVisible(false); // Close the modal
        setDeletedOrderId(null); // Reset the deleted order ID
        window.location.reload(); // Reload the page to start fresh
    };

    return (
        <div className="container text-center mt-4 position-relative">
            <div className="position-relative">
                <h1>AI飯店智能視覺辨認

                </h1>
                {!loading && (
                    <button
                        onClick={handleStartOver}
                        className="btn btn-primary position-fixed"
                        style={{
                            top: '10px',
                            right: '10px',
                            fontSize: '1.5rem',
                            padding: '0.5rem',
                            zIndex: 1000
                        }}
                    >
                        <FaRedo />
                    </button>
                )}
            </div>

            <p>點擊相機預覽拍攝照片，或上傳圖片。</p>

            {/* Image Upload and Paste Section */}
            <div className="row justify-content-center mt-4">
                <div className="col-md-6 col-sm-12 d-flex align-items-center">
                    <button
                        type="button"
                        className="btn btn-secondary me-2"
                        onClick={() => fileInputRef.current.click()}
                    >
                        +
                    </button>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="在此處貼上圖片或拖動圖片"
                        onPaste={handlePaste}
                        onKeyPress={handleKeyPress} // Disable text input while allowing image paste
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                    <button
                        type="button"
                        className="btn btn-primary ms-2 d-flex justify-content-center align-items-center"
                        onClick={handleUploadClick}
                        style={{ width: '100px' }}  // Set the width to 100px
                    >
                        <FaUpload className="me-1" /> 上傳
                    </button>
                </div>
            </div>

            {pastedImage && (
                <div className="d-flex justify-content-center">
                    <img src={pastedImage} alt="已貼上的照片" className="img-thumbnail" style={{ maxWidth: '350px', marginTop: '10px' }} />
                </div>
            )}

            {isCameraOpen && !uploadedImagePath && (
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6 col-sm-12">
                        <div className="position-relative">
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width="100%"
                                videoConstraints={videoConstraints}
                                onUserMediaError={() => setCameraError(true)}
                                onClick={handleTakeSnapshot}
                                style={{ cursor: 'pointer' }}
                            />
                            {cameraError && (
                                <div className="position-absolute top-50 start-50 translate-middle text-center text-danger fw-bold">
                                    相機預覽失敗
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {loading && (
                <div style={{
                    position: 'absolute',
                    top: '38%', // Position the loading spinner 30% from the top
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, 0)', // Adjust to center horizontally while keeping the 30% top margin
                    textAlign: 'center', // Center text within the container
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '1rem',
                    borderRadius: '10px',
                    zIndex: 1000, // Ensure the loading spinner is on top of other content
                }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-2">AI 分析中，請稍候...</p>
                </div>
            )}

            {noClothingDetected && !loading && (
                <div className="text-danger fw-bold mt-4">
                    無衣物被偵測或無法分析
                </div>
            )}

            {/* Updated Rendering Logic for New Response Format */}
            {responseData && (
                <div className="mt-3">
                    <h5>AI分析結果
                        {/* Display red or green light based on the warning value */}
                        <span style={{ marginLeft: '15px' }}>
                            {responseData && responseData.warning !== undefined ? (
                                responseData.warning ? (
                                    <FaCircle style={{ color: 'red', fontSize: '3rem' }} />  // Larger red icon for warning
                                ) : (
                                    <FaCircle style={{ color: 'green', fontSize: '3rem' }} />  // Larger green icon for normal
                                )
                            ) : null}  {/* Render nothing if responseData or warning is not available */}
                        </span>
                    </h5>
                    <table className="table table-bordered">
                        <tbody>
                            {Object.entries(responseData).map(([key, value]) => {
                                // Skip rendering this row if the value is empty ("") or null, undefined, or if the key is 'id' or 'time'
                                if (value === '' || value === null || value === undefined || key === 'id' || key === 'time') {
                                    return null;
                                }

                                // Check if the key is 'timestamp' to format the date using moment
                                if (key === 'timestamp') {
                                    value = moment(value).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss');
                                }

                                return (
                                    <tr key={key}>
                                        <th style={{ minWidth: '150px' }}>{keyTranslations[key] || key}</th>
                                        <td
                                            style={{
                                                maxWidth: '600px',   // Maximum width for the cell
                                                whiteSpace: 'normal', // Allow text to wrap to the next line
                                                overflow: 'hidden',   // Hide overflow content
                                            }}
                                        >
                                            {key === 'image_url' || key === 'thumbnail_url' ? (
                                                <img src={value} alt="AI generated content" style={{ width: key === 'thumbnail_url' ? '200px' : '500px' }} />
                                            ) : Array.isArray(value) ? (
                                                value.join(', ')  // If value is an array, join with commas
                                            ) : typeof value === 'boolean' ? (
                                                value ? '是' : '否'  // Convert boolean to '是' (Yes) or '否' (No)
                                            ) : (
                                                value
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}





            {/* Modal for Deletion Confirmation */}
            {modalVisible && (
                <div className="modal show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">訂單刪除成功</h5>
                                <button type="button" className="btn-close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <p>訂單號碼 {deletedOrderId} 已成功刪除。</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={closeModal}>確定</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default Construction;
